<template>
  <v-main :style="VMainStyle">
    <slot />
  </v-main>
</template>

<script setup lang="ts">
const props = defineProps({
  /** Предварительный padding.
   * Важно выставить если есть v-app-bar или v-system-bar, поскольку данный padding vuetify высчитывает на клиенте
   * из-за чего при SSR появляется дёрганье.
   **/
  preparatoryPadding: { type: [String, Number], default: 0 },
})

const isMounted = ref(false)

onMounted(() => {
  isMounted.value = process.client ?? false
})

const VMainStyle = computed(() => {
  if (isMounted.value) {
    return { }
  }

  return {
    paddingTop: `${props.preparatoryPadding}px`,
  }
})
</script>
