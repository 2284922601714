<template>
  <div
    class="snackbar-wrapper"
    :style="{ top: raised ? '-66px' : '0' }"
  >
    <v-snackbar
      :model-value="isActive"
      :timeout="timeout"
      absolute
      @update:model-value="close"
    >
      <div class="d-flex">
        <div class="flex-grow-1">
          {{ text }}
        </div>
        <v-btn
          v-if="cancelable"
          color="accent"
          variant="text"
          @click="cancelHandler"
        >
          Отменить
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
import { useSnackbarStore } from '~/stores/snackbar'

const { isActive, text, timeout, cancelable, raised, close, cancelHandler } = useSnackbarStore()
</script>

<style lang="scss">
.snackbar-wrapper {
  position: fixed;
  height: 100%;
  width: calc(100vw - 16px);
  pointer-events: none;
  top: 0;
  left: 8px;
  z-index: 1000;

  .v-snack__wrapper {
    max-width: 304px !important;
    min-width: 304px !important;
  }
}
</style>
