<template>
  <div>
    <slot />
    <client-only>
      <v-fade-transition>
        <v-btn
          v-show="isScrolled"
          id="test-btn"
          color="primary"
          class="up-btn"
          icon="ui-icon-arrow-up"
          elevation="6"
          :width="btnSize"
          :height="btnSize"
          @click="onScrollToTop"
        />
      </v-fade-transition>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'

const btnSize = '40px'

const { y } = useWindowScroll({ behavior: 'smooth' })

const isScrolled = computed(() => !process.server && y.value > window.innerHeight / 2)

function onScrollToTop() {
  y.value = 0
}
</script>

<style lang="scss" scoped>
.up-btn {
  margin-bottom: calc(v-bind(btnSize) / -2);
  margin-right: 16px;
  z-index: 1000;
  position: sticky;
  bottom: 24px;
  left: calc(100% - (v-bind(btnSize) / 4 * 3) - (100% - $container-max-width) / 2);
}
</style>
