<template>
  <v-app :theme="themeName">
    <AppBar :underline="$route.name !== 'index'" />

    <ContentWithScrollUpBtn>
      <BaseMain
        class="mb-8 mb-sm-13"
        :preparatory-padding="APP_HEADER_HEIGHT"
      >
        <slot />
      </BaseMain>
    </ContentWithScrollUpBtn>

    <AppFooter />

    <RedLineStage />
    <MainSnackbar />
  </v-app>
</template>

<script setup lang="ts">
import { APP_HEADER_HEIGHT } from '~/constants'

const $route = useRoute()
const themeName = computed(() => $route.fullPath.includes('grand-prix') ? 'dark' : 'light')
</script>
