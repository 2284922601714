<template>
  <div
    id="footer"
    class="py-4 pt-sm-6"
    :class="bgColor"
  >
    <div class="d-md-none">
      <div class="d-flex flex-column align-center">
        <a
          class="flex-grow-1"
          :href="pdUrl"
        >
          <v-img
            width="184px"
            :src="logoSrc"
          />
        </a>
      </div>

      <v-list class="mt-4 bg-transparent">
        <v-list-group
          v-for="group in footerMenu"
          :key="group.text"
        >
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              color="primary"
              :prepend-icon="group.icon"
              :title="group.text"
            />
          </template>

          <v-list-item
            v-for="item in group.subList"
            :key="item.text"
          >
            <NuxtLink
              v-if="item.to"
              class="text-decoration-underline text-ui-kit-text"
              :to="item.to"
            >
              {{ item.text }}
            </NuxtLink>
            <div
              v-else-if="item.href"
              class="d-flex align-center"
            >
              <a
                class="text-decoration-underline text-ui-kit-text"
                :href="item.href"
              >
                {{ item.text }}
              </a>
            </div>
            <div
              v-if="'on' in item"
              class="text-decoration-underline text-ui-kit-text"
              v-on="item.on"
            >
              {{ item.text }}
            </div>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider
        class="my-4"
        :style="{ borderColor }"
      />
    </div>
    <div class="d-none d-md-block">
      <v-container class="py-7">
        <v-row
          align="start"
          no-gutters
        >
          <v-col
            v-for="(group, index) in footerMenu"
            :key="group.text"
            :cols="group.cols || true"
          >
            <div>
              <a
                v-if="index === 0"
                :href="pdUrl"
              >
                <v-img
                  width="190"
                  height="28"
                  max-width="190"
                  max-height="28"
                  :src="logoSrc"
                />
              </a>
              <div v-else>
                <span class="text-subtitle-1">
                  {{ group.text }}
                </span>
              </div>
            </div>

            <div
              class="mt-5 cursor-pointer"
              style="max-width: 264px;"
            >
              <div
                v-for="(item, idx) in group.subList"
                :key="item.text"
                :class="{
                  'mb-3': idx !== group.subList.length - 1
                }"
              >
                <NuxtLink
                  v-if="item.to"
                  :to="item.to"
                >
                  <span class="text-decoration-underline text-ui-kit-text">
                    {{ item.text }}
                  </span>
                </NuxtLink>
                <div
                  v-else-if="item.href"
                  class="d-flex align-center"
                >
                  <a :href="item.href">
                    <span class="text-decoration-underline text-ui-kit-text">
                      {{ item.text }}
                    </span>
                  </a>
                </div>
                <div
                  v-if="item.on"
                  v-on="item.on"
                >
                  <span class="text-decoration-underline text-ui-kit-text">
                    {{ item.text }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-divider
        class="my-4"
        :style="{ borderColor }"
      />
    </div>

    <div
      class="text-center"
      :class="$vuetify.theme.current.dark ? 'text-ui-kit-bg-gray-60': 'text-ui-kit-text-secondary'"
    >
      &copy; 2020-{{ new Date().getFullYear() }}, Фонд «Фонд Премии ПроДокторов» ОГРН 1212300049301
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import { useTheme } from 'vuetify'
import { DOCS } from '~/constants/data'
import { AWARD_ROUTE_NAMES, DOCS_CURRENT_YEAR, GRAND_PRIX_ROUTE_NAMES } from '~/features/Home/constants'

const { public: { pdUrl } } = useRuntimeConfig()

const theme = useTheme()

const mainStore = useMainStore()

const footerMenu: Array<{
  text: string
  icon: string
  cols?: number
  subList: Array<{
    text: string
    href?: string
    to?: RouteLocationRaw
    on?: { click: Function }
  }>
}> = [
  {
    text: 'Премия ПроДокторов',
    icon: 'ui-icon-rating-star-outline',
    cols: 5,
    subList: [
      {
        text: 'Результаты премии',
        to: { name: AWARD_ROUTE_NAMES.main, params: { year: mainStore.awardYears[0] } },
      },
      {
        text: 'Результаты Гран-при',
        to: { name: GRAND_PRIX_ROUTE_NAMES.doctor, params: { year: mainStore.grandPrixYears[0] } },
      },
      {
        text: `Положение о Премии ${DOCS_CURRENT_YEAR}`,
        on: {
          click: () => open(DOCS[DOCS_CURRENT_YEAR], '_blank'),
        },
      },
      {
        text: 'Свидетельство о регистрации Фонда премии',
        on: {
          click: () => open('/documents/year-2021/Свидетельство_Фонд.pdf', '_blank'),
        },
      },
    ] as Array<{ text: string, on?: { click: Function }, to?: Object }>,
  },
  {
    text: 'Наши проекты',
    icon: 'ui-icon-layers',
    subList: [
      {
        text: 'ПроДокторов',
        href: pdUrl,
      },
      {
        text: 'ПроТаблетки',
        href: 'https://protabletky.ru',
      },
      {
        text: 'ПроБолезни',
        href: 'https://probolezny.ru',
      },
      {
        text: 'МедФлекс',
        href: 'https://medflex.ru/',
      },
      {
        text: 'МедТочка',
        href: 'https://medtochka.ru',
      },
      {
        text: 'МедОтвет',
        href: 'https://medotvet.me',
      },
      {
        text: 'МедЛок',
        href: 'https://medlock.ru/',
      },
    ] as Array<{ text: string, href: string }>,
  },
  {
    text: 'Оргкомитет премии',
    icon: 'ui-icon-phone-call',
    subList: [
      {
        text: 'awards@medrocket.ru',
        href: 'mailto:awards@medrocket.ru',
      },
      {
        text: '8 (800) 707-31-29',
        href: 'tel:+78007073129',
      },
      {
        text: '350000, г. Краснодар, а/я 3272, Фонд «Фонд Премии ПроДокторов»',
        on: {
          click: () => open('https://yandex.ru/maps/org/medreyting/112025382626/?ll=38.979802%2C45.034482&mode=search&sll=38.979802%2C45.034482&sspn=0.007703%2C0.003111&text=%D0%9E%D0%9E%D0%9E%20%C2%AB%D0%9C%D0%B5%D0%B4%D0%A0%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3%C2%BB&z=18', '_blank'),
        },
      },
    ] as Array<{ text: string, on?: { click: Function }, href: string }>,
  },
]

const logoSrc = computed(() => !theme.current.value.dark
  ? '/graphics/prodoctorov-logo-light.svg'
  : '/graphics/prodoctorov-logo-dark.svg')

const bgColor = computed(() => !theme.current.value.dark ? 'bg-ui-kit-bg-secondary' : 'bg-ui-kit-bg-gray-40')
const borderColor = computed(() => theme.current.value.dark
  ? theme.themes.value.dark.colors['ui-kit-bg-gray-20']
  : theme.themes.value.light.colors['ui-kit-bg-gray-60'])

function open(url: string, target: string) {
  window.open(url, target)
}
</script>
